import React, { useState } from "react"
import { Layout } from "components/layout"
import { Seo } from "components/seo"
import { StaticImage } from "gatsby-plugin-image"
import { CvBtn } from "components/hashtag/cv-btn"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faQuestionCircle } from "@fortawesome/free-regular-svg-icons"
import { Service } from "components/service"
import { SampleCardsA } from "components/hashtag/sample-cards-a"
import { SampleCardsB } from "components/hashtag/sample-cards-b"
import { DefaultHeader } from "../../../components/headers/default-header"

const HashtagCampaign = ({ location }) => {
  /** sampleタブ表示切り替え */
  const [isShowSampleA, setIsShowSampleA] = useState(true)
  const [isShowSampleB, setIsShowSampleB] = useState(false)
  const activeStyle = {
    borderBottom: "solid 4px #4bc2bb",
    color: "#4bc2bb",
  }
  const inActiveStyle = {
    borderBottom: "solid 1px #ededed",
    color: "#ededed",
  }

  const onClickShowSampleA = () => {
    setIsShowSampleA(true)
    setIsShowSampleB(false)
  }
  const onClickShowSampleB = () => {
    setIsShowSampleA(false)
    setIsShowSampleB(true)
  }
  /** UGC説明 */
  const [isShowUgc, setShouldShowUgc] = useState(false)
  return (
    <>
      <DefaultHeader lang="zh-CN" />
      <Layout lang="zh-CN">
        <Seo
          pagetitle="在Twitter上收集和发布UGC | 标签运动"
          pagedesc="自动收集和显示Twitter和Instagram上的标签帖子。了解PARKLoT为标签活动提供的服务，包括发布过程、广泛的功能和有竞争力的价格。"
          pagepath={location.pathname}
        />
        <main className="hashtag">
          <section className="hs__fv">
            <div className="hs__fv-container">
              <div className="hs__fv-text-inner">
                <div className="hs__fv-catch-container">
                  <p className="hs__fv-catch">惊人的价格</p>
                  <p className="hs__fv-catch">从短短一天的时间里</p>
                </div>
                <h1 className="hs__fv-h1">
                  <span>在现有的网站上收集和发布UGC，没有任何麻烦!</span>
                  <StaticImage
                    layout="constrained"
                    alt="在现有的网站上收集和发布UGC，没有任何麻烦!"
                    src="../../../images/product/hs-h1.png"
                  />
                </h1>
                <p className="hs__fv-lead">
                  自动收集和显示Twitter和Instagram上的标签帖子。非常适合于标签活动!
                </p>
              </div>
              <a
                className="hs__fv-cv-btn"
                href="https://hub.park-lot.com/hashtag-document-download"
                target="_blank"
                rel="noreferrer noopener"
              >
                下载该文件（免费）
              </a>
              <div className="hs__fv-img-container">
                <div className="hs__fv-img">
                  <StaticImage
                    layout="constrained"
                    alt="在现有的网站上收集和发布UGC，没有任何麻烦!"
                    src="../../../images/product/hs-fv.png"
                  />
                </div>
                <div className="hs__fv-ugc-btn-container">
                  <button
                    className="hs__fv-ugc"
                    type="button"
                    onClick={() => setShouldShowUgc(prevState => !prevState)}
                  >
                    <FontAwesomeIcon icon={faQuestionCircle} className="icon" />
                    什么是UGC？
                  </button>
                  {isShowUgc && (
                    <div className="hs__fv-ugc-detail-container">
                      用户产生的内容（UGC）是指由普通用户产生并发布在Twitter和Instagram等社交网站的照片和视频内容。
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
          <section className="hs__flow">
            <div className="hs__container">
              <div className="hs__heading">
                <h2 className="hs__title">Flow</h2>
                <p className="hs__subtitle">出版的步骤</p>
                <p className="hs__lead">
                  没有繁琐的收集和发布过程。
                  <br />
                  UGC可以很容易地实施，而且不费力气。
                </p>
              </div>
              <div className="hs__flow-container">
                <div className="hs__flow-card">
                  <StaticImage
                    layout="constrained"
                    alt="自动收取捐款"
                    src="../../../images/product/hs-flow_01.jpg"
                  />
                  <div className="hs__flow-card-textbox">
                    <h3 className="hs__flow-card-title">自动收取捐款</h3>
                    <p>
                      帖子会根据标签自动聚合在一起。视频、文本和图像都可以被收集。
                      (Twitter和Instagram兼容)
                    </p>
                  </div>
                </div>
                <div className="hs__flow-card">
                  <StaticImage
                    layout="constrained"
                    alt="收缴会费的管理"
                    src="../../../images/product/hs-flow-02-re.png"
                  />
                  <div className="hs__flow-card-textbox">
                    <h3 className="hs__flow-card-title">收缴会费的管理</h3>
                    <p>
                      可以从管理屏幕上查看所收集的内容。此外，每个帖子的显示功能可以轻松设置为显示或隐藏。
                    </p>
                  </div>
                </div>
                <div className="hs__flow-card">
                  <StaticImage
                    layout="constrained"
                    alt="帖子的嵌入"
                    src="../../../images/product/hs-flow-03.png"
                  />
                  <div className="hs__flow-card-textbox">
                    <h3 className="hs__flow-card-title">帖子的嵌入</h3>
                    <p>
                      它可以很容易地嵌入到你自己的网站或活动的LP中。用户的实时贡献可以自动发布。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="hs__feature">
            <div className="hs__container">
              <div className="hs__heading">
                <h2 className="hs__title">Feature</h2>
                <p className="hs__subtitle">职能</p>
                <p className="hs__lead">
                  它有一系列的功能，
                  <br />
                  可以大大削减从收集到出版的工作成本。
                </p>
              </div>
              <div className="hs__feature-container">
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="标签收集（兼容Twitter和Instagram）"
                    src="../../../images/product/collect.png"
                  />
                  <h3 className="hs__feature-card-title">
                    标签收集
                    <br />
                    <span>(兼容Twitter和Instagram)</span>
                  </h3>
                  <p className="hs__feature-card-text">
                    收集带有特定标签的帖子。文字、图像和视频也可以被收集。
                    最多可以设置四个不同的标签。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="报告提取"
                    src="../../../images/product/report.png"
                  />
                  <h3 className="hs__feature-card-title">
                    报告提取
                    <br />
                  </h3>
                  <p className="hs__feature-card-text">
                    你可以检查各种数字，如印象和参与度，这些都是分析帖子的重要指标。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="支持嵌入到现有的网站中"
                    src="../../../images/product/embed.png"
                  />
                  <h3 className="hs__feature-card-title">
                    支持嵌入到现有的网站中
                  </h3>
                  <p className="hs__feature-card-text">
                    UGC可以在你自己的网站或活动网站上发布，只需嵌入我们发布的标签即可。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="实时自动数据采集"
                    src="../../../images/product/automatic.png"
                  />
                  <h3 className="hs__feature-card-title">实时自动数据采集</h3>
                  <p className="hs__feature-card-text">
                    收集开始后，系统会自动实时收集特定的标签。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="带开/关的后期显示设置"
                    src="../../../images/product/choice.png"
                  />
                  <h3 className="hs__feature-card-title">
                    带开/关的后期显示设置
                  </h3>
                  <p className="hs__feature-card-text">
                    你可以设置是否发布。基本设置是关闭的，所以任何不适当的图像都不会被显示。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="彩票功能"
                    src="../../../images/product/lottery.png"
                  />
                  <h3 className="hs__feature-card-title">彩票功能</h3>
                  <p className="hs__feature-card-text">
                    获奖者是自动抽出的。
                    <br />
                    可以选择稍后抽签，也可以选择立即抽签。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="DM发送功能"
                    src="../../../images/product/email.png"
                  />
                  <h3 className="hs__feature-card-title">DM发送功能</h3>
                  <p className="hs__feature-card-text">
                    可以发送独特的礼品代码和URL作为中奖结果的通知。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="定制订单"
                    src="../../../images/product/sortby.png"
                  />
                  <h3 className="hs__feature-card-title">
                    定制订单
                    <br />
                    <span>※选择</span>
                  </h3>
                  <p className="hs__feature-card-text">
                    可以根据发布的日期和时间以及收到的喜欢的数量来定制订单。
                  </p>
                </div>
                <div className="hs__feature-card">
                  <StaticImage
                    className="hs__feature-card-icon"
                    layout="constrained"
                    placeholder="tracedSVG"
                    alt="设计定制化"
                    src="../../../images/product/design.png"
                  />
                  <h3 className="hs__feature-card-title">
                    设计定制化
                    <br />
                    <span>※选择</span>
                  </h3>
                  <p className="hs__feature-card-text">
                    也可以提供适合你自己的网站或活动网站的设计。
                  </p>
                </div>
              </div>
            </div>
          </section>
          <CvBtn lang="zh-CN" />
          <section className="hs__sample">
            <div className="hs__container">
              <div className="hs__heading hs__heading--sm">
                <h2 className="hs__title">Sample</h2>
                <p className="hs__subtitle">案例研究</p>
                <p className="hs__lead">仅仅通过嵌入代码就可以自动显示内容。</p>
              </div>
              <div className="hs__sample-button-container">
                <button
                  className="show-btn"
                  style={isShowSampleA ? activeStyle : inActiveStyle}
                  type="button"
                  onClick={onClickShowSampleA}
                >
                  样本A
                </button>
                <button
                  className="show-btn"
                  style={isShowSampleB ? activeStyle : inActiveStyle}
                  type="button"
                  onClick={onClickShowSampleB}
                >
                  样本B
                </button>
              </div>
              {isShowSampleA && <SampleCardsA />}
              {isShowSampleB && <SampleCardsB />}
              {/* <div className="hs__sample-display">
                <div id="pa-hashtag-item" data-id="1592183"></div>
              </div> */}
            </div>
          </section>
          {/* <section className="hs__price">
            <div className="hs__container">
              <div className="hs__heading hs__heading--sm">
                <h2 className="hs__title">Price</h2>
                <p className="hs__subtitle">收费</p>
                <p className="hs__lead">
                  对正在进行的UGC收集来说是负担得起的。
                </p>
              </div>
              <div className="hs__price-card-container">
                <div className="hs__price-card">
                  <h3 className="hs__price-card-title">基本费率</h3>
                  <div className="hs__price-card-content">
                    <h4 className="hs__price-card-subtitle">初始费用</h4>
                    <p className="hs__price-card-price">
                      80,000<span>日元</span>
                    </p>
                    <h4 className="hs__price-card-subtitle">每月金额</h4>
                    <p className="hs__price-card-price">
                      50,000<span>日元</span>
                    </p>
                    <h4 className="hs__price-card-subtitle">总金额</h4>
                    <p className="hs__price-card-price">
                      130,000<span>日元（143,000日元含税）</span>
                    </p>
                  </div>
                </div>
                <div className="hs__price-card">
                  <h3 className="hs__price-card-title hs__price-card-title--grey">
                    选择
                  </h3>
                  <div className="hs__price-card-content">
                    <div className="hs__price-card-content--2col">
                      <div>
                        <h4 className="hs__price-card-subtitle">收集好东西</h4>
                        <p className="hs__price-card-price">
                          30,000<span>日元</span>
                        </p>
                      </div>
                      <div>
                        <h4 className="hs__price-card-subtitle">回复收藏</h4>
                        <p className="hs__price-card-price">
                          30,000<span>日元</span>
                        </p>
                      </div>
                    </div>
                    <div className="hs__price-card-content--2col">
                      <div>
                        <h4 className="hs__price-card-subtitle">设计定制化</h4>
                        <p className="hs__price-card-price">
                          30,000<span>日元</span>
                        </p>
                      </div>
                      <div>
                        <h4 className="hs__price-card-subtitle">
                          排序顺序定制
                        </h4>
                        <p className="hs__price-card-price">
                          30,000<span>日元</span>
                        </p>
                      </div>
                    </div>
                    <h4 className="hs__price-card-subtitle">竞选LP创作机构</h4>
                    <p className="hs__price-card-price">
                      100,000<span>日元〜</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section> */}
          <CvBtn lang="zh-CN" />
          <Service />
        </main>
        {/* <script
          type="text/javascript"
          src="https://hashtag-campaigns.s3.ap-northeast-1.amazonaws.com/script/sample/hashtag-tweet.min.js"
        ></script> */}
      </Layout>
    </>
  )
}

export default HashtagCampaign
